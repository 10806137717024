import React, { useContext, useEffect, useState } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import { Activities } from "./Activities";
import ErrorBar from "src/components/ErrorBar";
import { ActivitiesUnity } from "./Activities.unity";
import { SlidesUnity } from "./Slides.unity";
import { useParams } from "react-router-dom";

export default function SlidesPageUnity() {
    const ability = useContext(AbilityContext);

    const { activityId } = useParams<{ activityId: string }>();

    const [id, setId] = useState<number>();
    useEffect(
        () => (activityId ? setId(parseInt(activityId)) : setId(undefined)),
        [activityId],
    );

    return (
        <>
            {id && <Container>
                {ability.can(Action.Read, Subjects.Activities) ? (
                    <SlidesUnity activityId={id} />
                ) : (
                    <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
                )}
            </Container>}
        </>
    );
}
