import { Action, Subjects } from "src/api/Permissions";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    useCreateActivityMutation,
    useDeleteActivityMutation,
    useGetActivityQuery,
    useGetActivitySlidesSummaryQuery,
    useGetAllActivitiesQuery,
} from "src/api/ActivityApi";
import { Activity, CreateActivityDto, Slide } from "src/api/generated.api";
import { Can } from "src/casl/Can";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../teams/context/team-context-provider";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import { Header } from "src/stories/Header";
import { Column, Row } from "react-table";
import moment from "moment";
import TableWithPaginationUnity from "src/components/tables/TableWithPagination.unity";
import RoutesDef from "src/routes/RoutesDef";
import TableSimple from "src/components/tables/TableSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

type SlidesParams = {
    activityId: number;
};

export function SlidesUnity({ activityId }: SlidesParams) {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);


    const { data, isLoading } = useGetActivityQuery({ activityId });

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const allActivitiesQueryProps = {
        limit: pageSize,
        offset: pageIndex * pageSize,
        filter,
        sort,
        teamId: currentTeam ? currentTeam.id : ""
    };



    const columns: Column<Slide>[] = [
        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: Slide } }) => (
                <div>{row.original.title}</div>
            ),
        },

        {
            Header: "Action",
            width: 160,
            Cell: ({ row }: { row: { original: Slide } }) => (
                <div>
                    <Button href={RoutesDef.UNITY_EDIT_SLIDE + '/' + row.original.id}>Edit position</Button>
                </div>
            ),
        },
    ];

    return (
        <div>

            <Link className="nav-link mt-4" to={RoutesDef.UNITY_ACTIVITIES} ><FontAwesomeIcon className="mr-2" icon={faBackward} /> Go back</Link>

            <div className="section mb-3 mt-0">
                <h1>Edit {data?.name} slides</h1>
            </div>

            <TableSimple
                columns={columns}
                data={data?.slides || []}
                fetchData={() => { }}
                loading={isLoading}
            />

        </div>
    );
}
