import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Action, Subjects } from "src/api/Permissions";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    useCreateCourseSessionMutation,
    useDeleteCourseSessionMutation,
} from "src/api/CourseSessionApi";
import { CourseSession, CreateCourseSessionDto, useCourseSessionControllerFindAllPaginatedQuery } from "src/api/generated.api";
import ConfirmDelete from "src/components/ConfirmDelete";
import TableSimple from "src/components/tables/TableSimple";
import { Badge, Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import { Can } from "src/casl/Can";
import { Formik, FormikHelpers } from "formik";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";

import { LangContext } from "src/lang/lang";

import { selectLoggedInUserName } from "../auth/authSlice";
import moment from "moment";
import TableWithPaginationUnity from "src/components/tables/TableWithPagination.unity";
import RoutesDef from "src/routes/RoutesDef";
import { useSelector } from "react-redux";
import { faArrowCircleRight, faBackward, faSync, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export default function SessionsUnity() {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(0);

    const [errorMessage, setErrorMessage] = React.useState("");

    const queryProps = {
        enrolled: true,
        mine: true,
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort
    }


    const {
        data: myCourseSessions,
        isFetching,
        isError: getIsError,
        error: getError,
        refetch,
    } = useCourseSessionControllerFindAllPaginatedQuery(queryProps);


    const objectEditRoute = "/sessions";
    type objectType = CourseSession;

    const myColumns = [
        {
            Header: ObjectNames.courseSession.en,
            width: undefined,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{row.original.name}</div>
            ),
        },
        {
            Header: "Owned by",
            width: 100,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{row.original.owners.map((user) => (
                    username === user.username ?
                        <span style={{ color: "#88a9f4" }}>Me</span> : <span className="text-gray">{user.username}</span>
                ))}</div>
            ),
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 100,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>
                    <Button href={RoutesDef.UNITY_RUN_SESSION + "/" + row.original.id}>Join</Button>
                </div>
            ),
        },
    ];


    const refresh = () => {
        refetch()
    }

    const username = useSelector(selectLoggedInUserName);

    return (
        <div>
            <Link className="nav-link mt-4" to={RoutesDef.UNITY_HOME} ><FontAwesomeIcon className="mr-2" icon={faBackward} /> Go back</Link>

            <div className="section mt-0">
                <h1>Dashboard</h1>
                <div className="subtitle">
                    Start a session here by choosing the one you want
                </div>

            </div>
            <div className="section">
                {/* <Col>
                    <Row className="unityTableHeader">
                        <Col style={{ width: "60%" }} className="unityCol" >Session name</Col>
                        <Col lg="3" className="unityCol text-center" >Created at</Col>
                        <Col lg="2" className="unityCol text-center">Owned by</Col>
                        <Col lg="1" />


                    </Row>
                    {myCourseSessions?.results.map(function (session) {
                        return (
                            <Row className="unityTable">
                                <Col style={{ width: "60%" }} className="unityCol" >{session.name}</Col>
                                <Col lg="3" className="unityCol text-center text-gray" >{moment(session.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Col>
                                <Col lg="2" className="unityCol text-center"> {session.owners.map((user) => (
                                    username === user.username ?
                                        <span style={{ color: "#88a9f4" }}>Me</span> : <span className="text-gray">{user.username}</span>
                                ))}</Col>
                                <Col lg="1">
                                    <Button href={"/session/view/" + session.id}>Join</Button>
                                </Col>

                            </Row>
                        );
                    })}
                </Col> */}
                <Button className="" onClick={refresh}>
                    <FontAwesomeIcon icon={faSyncAlt} className="mr-1" /> Refresh list
                </Button>
                <TableWithPaginationUnity
                    fetchData={(
                        pageIndexToFetch: number,
                        pageSizeToFetch: number,
                        filterToFetch: string,
                        sortToFetch: string
                    ) => {
                        setPageIndex(pageIndexToFetch);
                        setPageSize(pageSizeToFetch);
                        setFilter(filterToFetch);
                        setSort(sortToFetch);
                    }}
                    loading={isFetching}
                    columns={myColumns}
                    data={myCourseSessions?.results ?? []}
                    itemCount={myCourseSessions?.total ?? 0}
                    enableFiltering={true}
                    sortableProps={[
                        { name: 'Session', accessor: 'name' },
                        { name: 'Team', accessor: 'team.name' },
                        { name: 'Created at', accessor: 'createdAt' }
                    ]}
                />
            </div>
        </div>

    );
}
