import { Link, Navigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import RoutesDef from "src/routes/RoutesDef";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

export const BuilderModeUnity = () => {
    return (
        <Container>
            <Link className="nav-link mt-4" to={RoutesDef.UNITY_HOME} ><FontAwesomeIcon className="mr-2" icon={faBackward} /> Go back</Link>

            <div className="home-unity">
                <Row>
                    <Col className="my-auto">
                        <Link className="nav-link" style={{ flexDirection: "column" }} to={RoutesDef.UNITY_ACTIVITIES}>
                            <h2>Slide Deck Editor</h2>
                            <p>Edit spawn position on your slide</p>

                        </Link>
                    </Col>
                    <Col className="my-auto">
                        <Link className="nav-link" style={{ flexDirection: "column" }} to={RoutesDef.UNITY_SCENES}>
                            <h2 className="table-row">Scene Editor</h2>
                            <p>Add, edit and precisely position your 3d models on your scenes</p>
                        </Link>
                    </Col>
                </Row>
            </div>
        </Container >)
}