import React, { useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import { Activities } from "./Activities";
import ErrorBar from "src/components/ErrorBar";
import { ActivitiesUnity } from "./Activities.unity";

export default function ActivitiesPageUnity() {
    const ability = useContext(AbilityContext);

    return (
        <>
            <Container>
                {ability.can(Action.Read, Subjects.Activities) ? (
                    <ActivitiesUnity />
                ) : (
                    <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
                )}
            </Container>
        </>
    );
}
