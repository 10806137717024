import { Action, Subjects } from "src/api/Permissions";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    useCreateActivityMutation,
    useDeleteActivityMutation,
    useGetAllActivitiesQuery,
} from "src/api/ActivityApi";
import { Activity, CreateActivityDto } from "src/api/generated.api";
import { Can } from "src/casl/Can";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../teams/context/team-context-provider";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import { Header } from "src/stories/Header";
import { Column, Row } from "react-table";
import moment from "moment";
import TableWithPaginationUnity from "src/components/tables/TableWithPagination.unity";
import RoutesDef from "src/routes/RoutesDef";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export function ActivitiesUnity() {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [deleteActivity, { isError: deleteIsError, error: deleteError }] =
        useDeleteActivityMutation();
    const [createActivity, { isError: createIsError, error: createError }] =
        useCreateActivityMutation();

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const allActivitiesQueryProps = {
        limit: pageSize,
        offset: pageIndex * pageSize,
        filter,
        sort,
        teamId: currentTeam ? currentTeam.id : ""
    };

    const {
        data,
        isFetching,
        refetch,
        isError: getIsError,
        error: getError,
    } = useGetAllActivitiesQuery(allActivitiesQueryProps, {
        refetchOnMountOrArgChange: true,
    });

    const navigate = useNavigate();
    const onCreate = async (
        values: CreateActivityDto,
        formikBag: FormikHelpers<CreateActivityDto>,
    ) => {
        try {
            const activity = await createActivity({
                createActivityDto: values,
            }).unwrap();
            navigate(`/activities/${activity.id}`);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    useEffect(() => {
        const isErr = getIsError || deleteIsError || createIsError;
        const err = [getError, deleteError, createError].filter(
            (v) => v !== undefined,
        );
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [
        getIsError,
        deleteIsError,
        createIsError,
        getError,
        deleteError,
        createError,
    ]);

    const columns: Column<Activity>[] = [
        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>{row.original.name}</div>
            ),
        },

        {
            Header: "Created at",
            width: 300,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 100,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>
                    <Button href={RoutesDef.UNITY_SLIDES.substring(0, RoutesDef.UNITY_SLIDES.lastIndexOf("/") + 1) + row.original.id}>Edit</Button>
                </div>
            ),
        },
    ];

    const refresh = () => {
        refetch()
    }


    return (
        <div>
            <Link className="nav-link mt-4" to={RoutesDef.UNITY_BUILDER_MODE} ><FontAwesomeIcon className="mr-2" icon={faBackward} /> Go back</Link>

            <div className="section mb-3 mt-0">
                <h1>Slide decks editor</h1>
            </div>
            <div className="section">
                <Button className="" onClick={refresh}>
                    <FontAwesomeIcon icon={faSyncAlt} className="mr-1" /> Refresh list
                </Button>
                <TableWithPaginationUnity
                    fetchData={(
                        pageIndexToFetch: number,
                        pageSizeToFetch: number,
                        filterToFetch: string,
                        sortToFetch: string
                    ) => {
                        setPageIndex(pageIndexToFetch);
                        setPageSize(pageSizeToFetch);
                        setFilter(filterToFetch);
                        setSort(sortToFetch);
                    }}
                    loading={isFetching}
                    columns={columns}
                    data={data?.results || []}
                    itemCount={data?.total || 0}
                    enableFiltering={true}
                    sortableProps={[
                        { name: 'Name', accessor: 'name' },
                        { name: 'Created at', accessor: 'createdAt' }
                    ]}
                />
            </div>
        </div>
    );
}
